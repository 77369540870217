<template>
  <master-layout>

    <h1>Borászatot ajánlok</h1>
    <p>
      Melyik borászatot látnád szívesen a térképen? Írd meg nekünk, hogy fel tudjuk venni velük a kapcsolatot.
    </p>

    <ion-card>
      <ion-item lines="none">
        <ion-input
          v-model="myProposition.WineryName"
          placeholder="Borászat neve*"
        ></ion-input>
      </ion-item>
    </ion-card>

    <p>
      Minél több információt írsz le a borászatról, annál gyorsabban megtaláljuk. Hol található? Ki a kapcsolattartó? Kire hivatkozhatunk? stb.
    </p>

    <ion-card>
      <ion-item lines="none">
        <ion-textarea
          rows="5"
          v-model="myProposition.Comment"
          placeholder="pl: helység, telefonszám, honlap"
        >
        </ion-textarea>
      </ion-item>
    </ion-card>

    <ion-button
      v-if="myProposition.WineryName"
      shape="round"
      expand="block"
      @click="saveProposition()"
      class="first-button"
    >
      Küldés
    </ion-button>
    <ion-button
      v-else
      disabled
      shape="round"
      expand="block"
      class="first-button"
    >
      Küldés
    </ion-button>
    <ion-button expand="block" fill="transparent" color="secondary" @click="$router.go(-1)">Vissza</ion-button>

  </master-layout>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  IonButton, IonInput, IonTextarea, IonCard, IonItem, loadingController
} from '@ionic/vue'

export default {
  name: 'BoraszatAjanlas',
  components: {
    IonButton, IonInput, IonTextarea, IonCard, IonItem
  },
  data () {
    return {
      myProposition: {
        WineryName: '',
        Comment: ''
      }
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    })
  },
  watch: {
    'myProposition.WineryName': function(newValue) {
      this.myProposition.WineryName = this.capitalizeFirstLetter(newValue)
    },
    'myProposition.Comment': function(newValue) {
      this.myProposition.Comment = this.capitalizeFirstLetter(newValue)
    }
  },
  methods: {
    async saveProposition () {
      if (this.authData && this.authData.userId && this.authData.token) {
        this.presentLoading()
        await axios.post(process.env.VUE_APP_STRAPI_URI + 'partnership-recommendations',
          {
            User: this.authData.userId,
            WineryUserGenerated: this.myProposition.WineryName,
            Comment: this.myProposition.Comment
          },{
            headers: {
              Authorization:
              'Bearer ' + this.authData.token
            }
          }
        )
        await this.loading.dismiss()
        alert('Köszönjük, hogy ajánlottad: ' + this.myProposition.WineryName)
        this.myProposition.WineryName = ''
        this.myProposition.Comment = ''
      } else {
        alert('Hiba történt. Nem sikerült elküldeni a javaslatodat.')
      }
    },

    async presentLoading() {
      this.loading = await loadingController
        .create({
          spinner: 'lines'
        })
      await this.loading.present()
    },

    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}
</script>

<style scoped>
ion-button{
  margin: 5px 40px;
}
.first-button{
  margin-top: 25px;
  margin-bottom: 10px;
}
ion-card{
  margin: 0 20px;
}
p{
  margin: 25px 20px 20px 20px;
  line-height: 1.5;
}
</style>
